var Auth = (function($){
    
    var Auth = {
        
        init: function() {
            var self = this;
            $(document.body).on("click","#submit",function(e){
                if(!self.validateForm()) {
                    e.preventDefault();
                }
            });
        },
        validateForm: function() {
            
            var valid = true;
            
            var emailElement = $("#email");
            var passwordElement = $("#password");
            
            ElementStyle.removeAllErrorClasses();
            PopoverElement.hideAllPopovers();
            
            if(!Validator.validateEmail(emailElement.val()))
            {
                ElementStyle.addErrorClass(emailElement.closest(".form-group"));
                PopoverElement.popoverElement(emailElement);
                valid = false;
            }
            if(!Validator.validateEmpty(passwordElement.val()))
            {
                ElementStyle.addErrorClass(passwordElement.closest(".form-group"));
                PopoverElement.popoverElement(passwordElement);
                valid = false;
            }
            
            return valid;
        }
    };
    
    return Auth;
    
})(jQuery);


